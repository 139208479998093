import AvocadoBrand from '../Icons/AvocadoBrand'
import BeautyRestBrand from '../Icons/BeautyRestBrand'
import CasperBrand from '../Icons/CasperBrand'
import IntellibedBrand from '../Icons/IntellibedBrand'
import LullBrand from '../Icons/LullBrand'
import MattressFirmBrand from '../Icons/MattressFirmBrand'
import NectarBrand from '../Icons/NectarBrand'
import PurpleBrand from '../Icons/PurpleBrand'
import SealyBrand from '../Icons/SealyBrand'
import SertaBrand from '../Icons/SertaBrand'
import SleepDotComBrand from '../Icons/SleepDotComBrand'
import SleepysBrand from '../Icons/SleepysBrand'
import StearnsAndFosterBrand from '../Icons/StearnsAndFosterBrand'
import TempurPedicBrand from '../Icons/TempurPedicBrand'
import TuftAndNeedleBrand from '../Icons/TuftAndNeedleBrand'
import TuloBrand from '../Icons/TuloBrand'
import { Product } from '../Favorites/interfaces/favorites'
import KingKoil from '../Icons/KingKoil'
import ChattamAndWells from '../Icons/ChattamAndWells'
import ReverieIcon from '../Icons/ReverieIcon'
import PressureSmart from '../Icons/PressureSmart'
import MantuaBrand from '../Icons/MantuaBrand'
import BeautySleep from '../Icons/BeautySleep'

type BrandLogoProps = {
  product: Product
  className?: string
}

const fallback = (src: string, alt = '') => (
  <img
    src={src}
    alt={alt}
    style={{
      objectFit: 'scale-down',
      objectPosition: '0 0',
      height: '40px',
      width: '100%'
    }}
  />
)

const renderBrandLogo = (product: Product): JSX.Element => {
  switch (product.brand?.id) {
    case 'king-koil':
      return <KingKoil height={40} />
    case 'avocado':
      return <AvocadoBrand height={40} />
    case 'nectar':
      return <NectarBrand height={40} />
    case 'casper':
      return <CasperBrand className="" height={40} />
    case 'mattress-firm':
      return <MattressFirmBrand height={40} fillColor="#333030" />
    case 'sleep-com':
      return <SleepDotComBrand height={40} />
    case 'beautyrest':
      return <BeautyRestBrand height={40} />
    case 'beautysleep':
    case 'beauty-sleep':
      return <BeautySleep height={40} />
    case 'intellibed':
      return <IntellibedBrand height={40} fillColor="inherit" />
    case 'lull':
      return <LullBrand height={40} />
    case 'kingsdown':
      return fallback('/img/logos/kingsdown_logo.jpeg', 'Kingdown')
    case 'purple':
      return <PurpleBrand height={40} />
    case 'sealy':
      return <SealyBrand height={40} fillColor="#231E1F" secondaryColor="#231E21" />
    case 'serta':
      return <SertaBrand height={40} />
    case 'sleepys':
      return <SleepysBrand height={40} />
    case 'stearns-and-foster':
    case 'stearns-foster':
      return <StearnsAndFosterBrand height={40} />
    case 'tempur-pedic':
      return <TempurPedicBrand height={59} width={145} />
    case 'tuft-needle':
      return <TuftAndNeedleBrand height={40} />
    case 'tulo':
      return <TuloBrand height={40} />
    case 'chattam-wells':
      return <ChattamAndWells height={60} width={60} />
    case 'reverie':
      return <ReverieIcon width={150} />
    case 'pressuresmart':
      return <PressureSmart width={225} />
    case 'mantua':
      return <MantuaBrand width={172} height={22} />
    default:
      return fallback(
        product.logo ?? product.brand?.logo
          ? `/img/logos/${product.logo ?? product.brand?.logo}`
          : `https://via.placeholder.com/100x40/F8F9FA/2D2926?text=${product.brand?.name}`
      )
  }
}

const BrandLogo = ({ product, className = '' }: BrandLogoProps) => (
  <div className={`brand-logo ${className}`}>{renderBrandLogo(product)}</div>
)

BrandLogo.defaultProps = {
  className: ''
}

export default BrandLogo
