import clsx from 'clsx'
import React, { ChangeEvent, FunctionComponent, useState, useEffect, useRef, useMemo } from 'react'
import Button from '../Button'
import SearchAlt from '../Icons/SearchAlt'
import Input from '../Input'
import useStorage from '../useStorage/useStorage'
import { IStorageType, IUnifySleepExpertDetail, MFRM_UNIFY_SE_DETAIL_KEY } from '../conventions'
import IHorizontalBarProps from './interfaces/horizontalBar'
import styles from './horizontalBar.module.scss'
import HamburgerMenuAlt from '../Icons/HamburgerMenuAlt'
import { mockAvatarDetail } from '../VerticalBar/mocks'
import UserSection from './UserSection'
import HamburgerMenu from './HamburgerMenu'
import { DEFAULT_USER_GUIDE_URL, DEFAULT_MERGE_GUIDE_URL } from './consts/menu'
import useCustomer from '../SessionExpireWrapper/hooks/useCustomer'
import { SEARCH_REGEXP } from './consts/search'

const HorizontalBar: FunctionComponent<IHorizontalBarProps> = ({
  bodyContent,
  fixed = false,
  searchFormClass,
  wrapperClass,
  handleSearchSubmit,
  handleAddProfileClick,
  handleProfileClick,
  handleNewSessionClick,
  setIdPrefixEditStoreModal,
  onEditStoreClick,
  profileLinkProps = { btnType: 'link' },
  addProfileButtonProps = { label: 'Save Profile', size: 'sm' },
  logoText = 'DreamHUB',
  idPrefix = '',
  value,
  calculatorControls,
  userGuideLink = DEFAULT_USER_GUIDE_URL,
  mergeGuideLink = DEFAULT_MERGE_GUIDE_URL,
  isEditStoreEnabled,
  ...rest
}) => {
  const horizontalClasses = clsx(styles.horizontal, fixed && styles.fixed, wrapperClass)
  const [searchTerm, setSearchTerm] = useState('')
  const [storageProfileDetail] = useCustomer()
  const isInvalidSearch = useMemo(() => Boolean(searchTerm.match(SEARCH_REGEXP)), [searchTerm])

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [storageDetail] = useStorage<IUnifySleepExpertDetail>({
    key: MFRM_UNIFY_SE_DETAIL_KEY,
    value: mockAvatarDetail,
    storageType: IStorageType.Cookie
  })

  const sleepExpertName = storageDetail?.name || mockAvatarDetail.name
  const sleepExpertStore = storageDetail?.storeNumber || mockAvatarDetail.storeNumber

  const outsideClickMenuHandler = (e: MouseEvent) => {
    const menuBlock = document.querySelector('#navigation_menu')
    let currentNode = e.target as Element | Document
    while (currentNode?.parentElement) {
      if (currentNode === menuBlock) return
      currentNode = currentNode?.parentElement
    }
    setIsMenuOpen(false)
  }

  const handleEscape = (e: KeyboardEvent) => {
    if (e?.key === 'Escape') {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setSearchTerm(value)
    }
  }, [value])

  useEffect(() => {
    if (idPrefix && setIdPrefixEditStoreModal) {
      setIdPrefixEditStoreModal(idPrefix)
    }
  }, [idPrefix, setIdPrefixEditStoreModal])

  const inputRef = useRef<HTMLInputElement>()

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    inputRef.current?.blur()
    if (handleSearchSubmit && searchTerm?.length > 1 && !isInvalidSearch) {
      handleSearchSubmit(searchTerm)
    }
  }

  const handleSearchTerm = ({ target: { value: searchValue } }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(searchValue)
  }

  const toggleMenu = (e: React.MouseEvent | React.KeyboardEvent) => {
    e?.stopPropagation()
    if (isMenuOpen) {
      document.removeEventListener('click', outsideClickMenuHandler)
      document.removeEventListener('keydown', handleEscape)
    } else {
      document.addEventListener('click', outsideClickMenuHandler)
      document.addEventListener('keydown', handleEscape)
    }
    setIsMenuOpen(!isMenuOpen)
  }

  const handleSubmitButtonClick = () => {
    if (!isInvalidSearch) {
      handleSearchSubmit(searchTerm)
    }
  }
  const searchFormIcon = <SearchAlt width={17.5} height={17.5} fillColor="#626366" />
  const searchFormClasses = clsx(styles.searchForm, searchFormClass)
  const searchFormSubmitBtn = (
    <Button
      data-testid="submit-button"
      className={styles.searchFormSubmit}
      disabled={searchTerm?.length < 2 || isInvalidSearch}
      onClick={handleSubmitButtonClick}
      size="lg"
      id={`${idPrefix}search_submit_button`}
    >
      {searchFormIcon}
    </Button>
  )

  const onUserGuideClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    window.open(userGuideLink, '_blank')?.focus()
    toggleMenu(e)
  }

  const onMergeGuideClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    window.open(mergeGuideLink, '_blank')?.focus()
    toggleMenu(e)
  }

  const onEditMyStoreClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    onEditStoreClick?.()
    if (isMenuOpen) {
      toggleMenu(e)
    }
  }

  const onEditMyStoreClickHandler = isEditStoreEnabled ? onEditMyStoreClick : undefined

  if (typeof bodyContent !== 'undefined') {
    return (
      <header className={styles.horizontal} data-testid="horizontal-bar" {...rest}>
        {bodyContent}
      </header>
    )
  }

  return (
    <header className={horizontalClasses} data-testid="horizontal-bar" {...rest}>
      <div className={styles.logoText}>{logoText}</div>
      <div className={styles.hamburgerMenuWrapper}>
        <HamburgerMenuAlt onClick={toggleMenu} id={`${idPrefix}hamburger_menu_icon_button`} />
        {isMenuOpen ? (
          <HamburgerMenu
            idPrefix={idPrefix}
            logoText={logoText}
            sleepExpertName={sleepExpertName}
            sleepExpertStore={sleepExpertStore}
            onUserGuideClick={onUserGuideClick}
            onMergeGuideClick={onMergeGuideClick}
            onEditStoreClick={onEditMyStoreClickHandler}
            calculatorControls={calculatorControls}
          />
        ) : null}
      </div>
      <div className={styles.searchFormContainer}>
        <form className={searchFormClasses} id={`${idPrefix}searchForm`} role="search" onSubmit={handleSubmit}>
          <Input
            ref={inputRef}
            required
            aria-label="Search for a sleep profile"
            autoComplete="off"
            autoCorrect="off"
            className={styles.searchField}
            id={`${idPrefix}input_search_term`}
            rightSide={searchFormSubmitBtn}
            rightSideWrapperClass={styles.searchFormSubmitWrapper}
            name="searchTerm"
            placeholder="Search..."
            size="md"
            spellCheck={false}
            type="search"
            value={searchTerm}
            onChange={handleSearchTerm}
            data-testid="search"
            error={isInvalidSearch}
          />
        </form>
      </div>
      <div className={styles.settingsSection}>
        <UserSection
          idPrefix={idPrefix}
          profileDetails={storageProfileDetail}
          handleAddProfileClick={handleAddProfileClick}
          handleProfileClick={handleProfileClick}
          handleNewSessionClick={handleNewSessionClick}
          profileLinkProps={profileLinkProps}
          addProfileButtonProps={addProfileButtonProps}
        />
      </div>
    </header>
  )
}

export default HorizontalBar
